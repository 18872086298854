<script lang="ts" context="module">
    import { BuyerProfileUpdateDocument, UserUpdateCodesDocument } from '_config/graphql/tags-generated'

    import { runMutation, runQueryWithPromise } from 'helpers/graphql-query'
    import { BuyerProfileDocument } from '_config/graphql/tags-generated'
    import { goto } from '$app/navigation'

    export async function getBuyerProfile(buyerProfileId: string, client: GraphqlClient) {
        const result = await runQueryWithPromise(BuyerProfileDocument, { id: buyerProfileId as string } as SomeObject, client)
        if (result?.error) goto(`/permission-denied#profile/getBuyerProfile/${buyerProfileId}`)

        return result?.data?.buyerProfileById as BuyerProfileResponse
    }

    export async function updateBuyerProfile(profileUpdateRequest: BuyerProfileUpdateMutationVariables, client: GraphqlClient) {
        if (!profileUpdateRequest) return

        const result = await runMutation(BuyerProfileUpdateDocument, profileUpdateRequest, client)
        if (result?.error) goto(`/error#profile/updateBuyerProfile`)

        return result?.data?.userUpdate
    }

    export async function updateCodes(request: UserUpdateCodes, client: GraphqlClient) {
        if (!request) return

        const result = await runMutation(UserUpdateCodesDocument, request, client)
        if (result?.error) goto(`/error#signup/confirm`)

        return result?.data
    }
</script>
